import React, { useState, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import AddIcon from '@material-ui/icons/Add';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import DynamicForm from "f1-dynamic-form-react";
import { personalDetails, firstLifeToBeAssured, beneficaryDetails, investmentDetails } from './infinityLifeJson'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  backButton: {
    marginRight: theme.spacing(1),
    border: "1px solid black"
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

function getSteps() {
  return ['PERSONAL DETAILS', 'FIRST LIFE TO BE ASSURED', 'BENEFICARY(S) DETAILS', 'INVESTMENT DETAILS'];
}



export default function HorizontalLabelPositionBelowStepper() {

  const [formJson, setFormJson] = React.useState({
    personalDetails: personalDetails,
    firstLifeToBeAssured: firstLifeToBeAssured,
    beneficaryDetails: beneficaryDetails,
    investmentDetails: investmentDetails
  })
  // const [personalDetailsDefaultValue, setPersonalDetailsDefaultValue] = React.useState({
  //   policyType: '',
  //   policyCurrency: '',
  //   policyOwner: ''
  // })

  const [firstLifeToBeAssuredDefaultValue, setFirstLifeToBeAssuredDefaultValue] = React.useState({
    title: '',
    firstName: "",
    lastName: "",
    gender: '',
    countryOfBirth: "",
    nationality: "",
    countryOfResidence: "",
    Date: new Date,
    maritalStatus: '',
    residentialAddress: "",
    adressStatus: '',
    correspondanseAddress: "",
    homeCountryAddress: "",
    mobileNumber: '',
    email: "",
    telephoneNumber: '',
    faxNumber: '',
    occupationAndPosition: "",
    companyName: " ",
    companyAddress: ""


  })

  const [investmentDetailsDefaultValue, setInvestmentDetailsDefaultValue] = React.useState({
    investmentLabel1: "BGF World Gold A2",
    investment1: 25,
    investmentLabel2: "FRANKLIN US OPPORTUNITIES",
    investment2: 25,
    investmentLabel3: "PICTET CH PM",
    investment3: 50,
    investmentLabel4: "TOTAL",
    investment4: 100
  })
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const steps = getSteps();
  const [inputList, setInputList] = useState([{ firstName: "", lastName: "" }]);
  const [inputListNominee, setInputListNominee] = useState([{ nameBenificiary: "", DOB: "", relationToLifeAssured: "", percentage: "" }]);
  let [age, setAge] = React.useState(20);
  const [total, setTotal] = React.useState(0);
  const [Percentage, setPercentage] = React.useState(0);
  //const dynamicFormRef = useRef(null);
  const [formData, setFormData] = React.useState({});
  const dynamicFormRef = useRef(null);
  let allData = {}
  const onSubmit = (model, value) => {
    console.log('model', model);
    allData = { ...formData, ...model }
    if (model.isValidForm || Object.keys(model.errors).length === 0) {

      if (value == 'step3' && Percentage > 100) {
        alert('Total percentage should not be more than 100%')
      } else if (value == 'step3' && Percentage < 100) {
        alert('Total percentage should not be less than 100%')
      } else if (value == 'step4' && total > 100) {
        alert('Total percentage should not be more than 100%')
      } else if (value == 'step4' && total < 100) {
        alert('Total percentage should not be less than 100%')
      } else {
        setFormData(allData)

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        if (value === 'step2') {
          alert("Data Saved Successfully!!!!")
        }
      }



    }
    //   /  setFormDataAll()
    console.log("hhhh", allData)
  }
  // const handleNext = () => {
  //     
  //   setActiveStep((prevActiveStep) => prevActiveStep + 1);
  // };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  // handle input change
  const handleInputChange = (e, index) => {

    const { name, value } = e.target;
    const list = [...inputList];
    list[index][name] = value;
    let tempTotal = 0
    for (var i = 0; i < list.length; i++) {
      tempTotal = tempTotal + parseInt(list[i].lastName);
    }

    if (tempTotal) {
      setTotal(tempTotal);
    }

    setInputList(list);
  };

  // handle click event of the Remove button
  const handleRemoveClick = index => {
    const list = [...inputList];
    list.splice(index, 1);
    let tempTotal = 0
    for (var i = 0; i < list.length; i++) {
      tempTotal = tempTotal + parseInt(list[i].lastName);
    }
    if (tempTotal) {
      setTotal(tempTotal);
    }
    setInputList(list);
  };

  // handle click event of the Add button
  const handleAddClick = () => {
    console.log('inputList', inputList);
    setInputList([...inputList, { firstName: "", lastName: "" }]);
  };

  const handleInputChangeNominee = (e, index) => {

    const { name, value } = e.target;
    const list = [...inputListNominee];
    list[index][name] = value;
    let tempTotal = 0;
    console.log(list);
    for (var i = 0; i < list.length; i++) {
      tempTotal = tempTotal + parseInt(list[i].percentage);
    }
    for (var i = 0; i < list.length; i++) {
      let tempAge = getAge(list[i].DOB);
      console.log('tempAge', tempAge);
      if (tempAge < 18) {
        setAge(tempAge);
      }
      //else{
      //   setAge(20);
      // }
    }

    if (tempTotal) {
      setPercentage(tempTotal);
    }

    setInputListNominee(list);
  };



  function getAge(dateString) {
    var today = new Date();
    var birthDate = new Date(dateString);
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  }

  // handle click event of the Remove button
  const handleRemoveClickNominee = index => {
    const list = [...inputListNominee];
    list.splice(index, 1);
    let tempTotal = 0
    for (var i = 0; i < list.length; i++) {
      tempTotal = tempTotal + parseInt(list[i].percentage);
    }
    for (var i = 0; i < list.length; i++) {
      let tempAge = getAge(list[i].DOB);
      console.log('tempAge', tempAge);
      if (tempAge < 18) {
        setAge(tempAge);
      }
      else {
        setAge(20);
      }
    }

    debugger;
    if (tempTotal) {
      setPercentage(tempTotal);
    }
    setInputListNominee(list);
  };

  // handle click event of the Add button
  const handleAddClickNominee = () => {
    console.log('inputListNominee', inputListNominee);

    setInputListNominee([...inputListNominee, { nameBenificiary: "", DOB: "", relationToLifeAssured: "", percentage: "" }]);


  };

  const formOnSpecialRequestChangeHandler = (event, key) => {
    let dynamicFormRefLocal = dynamicFormRef.current.state;
    console.log(dynamicFormRefLocal);
    if(key=='title'){
    if (event.target.value == 'mr') {
      dynamicFormRefLocal.advanceLayoutFields[3].options = [{ key: "male", label: "Male", value: "male" }];
    } else if (event.target.value == 'mrs' || event.target.value == "miss") {
      dynamicFormRefLocal.advanceLayoutFields[3].options = [{ key: "female", label: "Female", value: "female" }];

    }
    else {
      dynamicFormRefLocal.advanceLayoutFields[3].options = [{
        "key": "Select",
        "label": "Please Select Gender ",
        "value": "Select"
      },
      {
        "key": "male",
        "label": "Male",
        "value": "male"
      },
      {
        "key": "female",
        "label": "Female",
        "value": "female"
      }];
    }
    if (event.target.value == 'mrs') {
      dynamicFormRefLocal.advanceLayoutFields[6].options = [{ key: "married", label: "Married", value: "married" }];
    } else {
      dynamicFormRefLocal.advanceLayoutFields[6].options = [
        {
          "key": "Select",
          "label": "Please Select Marital Status",
          "value": ""
        },
        {
          "key": "single",
          "label": "Single",
          "value": "single"
        },
        {
          "key": "married",
          "label": "Married",
          "value": "married"
        },
        {
          "key": "other",
          "label": "Other",
          "value": "other"
        }
      ]
    }
  }
  }


  const getStepContent = (stepIndex) => {

    switch (stepIndex) {
      case 0:
        return (
          <div className="buttonSetL">

            <DynamicForm
              // key={this.state.current.id}
              className="form step1"
              title=" "
              buttonSubmit="Next"
              defaultValues={{}}
              model={formJson.personalDetails}
              onSubmit={(model) => {
                onSubmit(model, 'step1');
              }}
              // disableSubmitOnInValid={true}
              // customValidators={this.customValidators}
              // displayInformation={true}
              // displaydetails="Please fill all required fields."
              design="advanceBootstrap" // material
            // extErrors={this.state.extErrors} // pass external error messages here if any
            // onChange={this.onChangeHandler}
            // onAutocompleteSelect={this.onAutocompleteSelect}
            // autoCompleteItems={this.autoCompleteItems}

            />
          </div>

        );
      case 1:
        return <DynamicForm
          // key={this.state.current.id}
          className="form step2"
          title=" "
          buttonSubmit="Next"

          defaultValues={firstLifeToBeAssuredDefaultValue}
          model={formJson.firstLifeToBeAssured}
          onSubmit={(model) => {
            onSubmit(model, 'step2');
          }}
          onRef={el => {
            dynamicFormRef.current = el;
          }}
          onChange={formOnSpecialRequestChangeHandler}
          design="advanceBootstrap" // material

        />;
      case 2:
        // return <DynamicForm
        //   // key={this.state.current.id}
        //   className="form"
        //   title=" "
        //   buttonSubmit="Next"
        //   // defaultValues={this.state.current}
        //   model={formJson.beneficaryDetails}
        //   onSubmit={(model) => {
        //     onSubmit(model);
        //   }}

        //   design="advanceBootstrap" // material

        // />;;
        return (
          <div className="App step3">

            {inputListNominee.map((x, i) => {
              return (
                <div className="box">
                  <input
                    name="nameBenificiary" placeholder="Name of Beneficary(s)"
                    value={x.nameBenificiary}
                    onChange={e => handleInputChangeNominee(e, i)}
                  />
                  <input type="date"
                    name="DOB" placeholder="Date of Birth"
                    value={x.DOB}
                    onChange={e => handleInputChangeNominee(e, i)}
                  />
                  <input
                    name="relationToLifeAssured" placeholder="Relationship to Life to be Assured"
                    value={x.relationToLifeAssured}
                    onChange={e => handleInputChangeNominee(e, i)}
                  />
                  <input type="number"
                    className="ml10"
                    name="percentage"
                    placeholder="Percentage"
                    value={x.percentage}
                    onChange={e => handleInputChangeNominee(e, i)}
                  />
                  <div className="btn-box">
                    {inputListNominee.length !== 1 && <button
                      className="mr10 btnRemove"
                      onClick={() => handleRemoveClickNominee(i)}><RemoveCircleOutlineIcon className="icn" />Remove</button>}
                    {inputListNominee.length - 1 === i && <button style={{ border: "1px solid #CB1F2D!important", color: "#CB1F2D" }} onClick={handleAddClickNominee} disabled={Percentage >= 100 || inputListNominee.length >= 4}><AddIcon className="icn" /> Add New</button>}
                  </div>

                </div>

              );

            })}
            {<div style={{ marginTop: 20 }}><p>Total Percentage:</p>{Percentage}</div>}
            {age < 18 ?
              <div>
                <h3>If beneficary(s) is a minor, Please give apointee details.</h3>
                <input type="text"
                  name="relationToLifeAssured" placeholder="Name of Appointee"


                />
                <input type="date" max="2002-10-21"
                  name="relationToLifeAssured" placeholder="Date of Birth"

                />
                {/* {appointeAge<18?<p>Age should be greater than 18</p>:""} */}
                <input type="text"
                  name="relationToLifeAssured" placeholder="Relationship to Beneficary(s)"

                />
              </div>
              : ""}

            <div>
              <DynamicForm
                // key={this.state.current.id}
                className="form step3"
                title=" "
                buttonSubmit="Next"
                // defaultValues={this.state.current}
                model={formJson.beneficaryDetails}
                onSubmit={(model) => {
                  onSubmit(model, 'step3');
                }}

              //   design="advanceBootstrap" // material

              />
            </div>

          </div>
        );
      case 3:
        return (
          <div className="App step4">

            {inputList.map((x, i) => {
              return (
                <div className="box">
                  <input
                    name="firstName"
                    placeholder="Enter Fund Name"
                    value={x.firstName}
                    onChange={e => handleInputChange(e, i)}
                  />
                  <input type="number"
                    className="ml10"
                    name="lastName"
                    placeholder="Enter Fund Value"
                    value={x.lastName}
                    onChange={e => handleInputChange(e, i)}
                  />
                  <div className="btn-box">
                    {inputList.length !== 1 && <button
                      className="mr10 btnRemove"
                      onClick={() => handleRemoveClick(i)}>Remove</button>}
                    {inputList.length - 1 === i && <button onClick={handleAddClick} disabled={total >= 100}><AddIcon className="icn" />Add</button>}
                  </div>
                </div>
              );
            })}
            <div style={{ marginTop: 20 }}><p>Total:</p>{total}</div>
            <div>
              <DynamicForm
                // key={this.state.current.id}
                className="form step4"
                title=" "
                buttonSubmit="Next"
                // defaultValues={this.state.current}
                model={formJson.beneficaryDetails}
                onSubmit={(model) => {
                  onSubmit(model, 'step4');
                }}

              //   design="advanceBootstrap" // material

              />
            </div>
          </div>
        );
      // case 4:
      // return 'Premium Payment';
      // case 5:
      // return 'Insurance Coverage Details';
      // case 6:
      // return 'Health Questionnaire';
      default:
        return 'Investment Details';        //Premium Payment Insurance Coverage Details Health Questionnaire
    }
  }

  return (
    <div className={classes.root}>
      <Stepper activeStep={activeStep} alternativeLabel>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <div className="pageContent">
        <h4>Please provide us your details to process further</h4>
        <p>Our comprehensive coverage is designed to provide your family with firm support through every contingency.</p>
      </div>
      <div>
        {activeStep === steps.length ? (
          <div>
            <Typography className={classes.instructions}>All steps completed</Typography>
            <Button color="primary" onClick={handleReset} style={{ backgroundColor: "#CF202E", color: "white" }}>Reset</Button>
          </div>
        ) : (
            <div>
              <Typography className={classes.instructions}>{getStepContent(activeStep)}</Typography>
              <div className="buttonSet2">
                <Button
                  disabled={activeStep === 0}
                  onClick={handleBack}
                  className={classes.backButton}
                >
                  Back
                </Button>
                {/* <Button variant="contained" color="primary" onClick={handleNext}>
                  {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                </Button> */}
              </div>
            </div>
          )}
      </div>
    </div>
  );
}


