import React from "react";
import './login.css';
import '../../App.css';
import commonLogo from '../../img/logo.jpg';
// import homeBanner from '../../img/homeBanner.jpg';



//import HorizontalLabelPositionBelowStepper from './infinityLifeStepperForm'

class Home extends React.Component {
  constructor() {
    super();
    this.state = {
      selectedProduct: 1,
      userName:'',
      password:'',
      
    };
  }
  //const [inputList, setInputList] = useState([{ firstName: "", lastName: "" }]);
  handleChange = (e) => {
    this.setState({ selectedProduct: e.target.value });
    // if (e.target.value == 1) {
    //   this.props.history.push("/home");
    // } else if (e.target.value == 2) {
    //   this.props.history.push("/infinityLife");
    // } else {
    //   this.props.history.push("/superSecure");
    // }

  };
    handleUserName=(e)=>{
        this.setState({ userName: e.target.value });
  }

  handlePassword=(e)=>{
    this.setState({ password: e.target.value });
  }

  redirectToPage=(x)=>{
      console.log('xxx',x)
      if(this.state.userName=='admin' && this.state.password=='admin'){
        if(this.state.selectedProduct){
            if (this.state.selectedProduct == 2) {
                this.props.history.push("/qualityCheck");
              } else if (this.state.selectedProduct == 3) {
                this.props.history.push("/home");
              } 
          }
      }else{
          alert('Please enter correct user name and password');
      }
     
     
    // else {
    //   this.props.history.push("/superSecure");
    // }
  }

  render() {
    return (
      
      <div className="loginWrapper">
      <div className="bannerBox">
      
      {/* <img src={homeBanner} className="homeBanner fl" /> */}
      

      <div className="bannerInner">
      <h4>We Promise</h4>
      <p className="one">Exceptional customer service</p>
      <p>From guiding you towards the right policy to helping you customize it according to your specific needs while providing support at all times, we are with you every step of the way.</p>
      </div>
      </div>
      <div className="loginBox fr">
      {/* <p>Welcome To</p>
        <img src={commonLogo} className="logo" /> */}
        <label>User Name :</label>
        <input
         name="firstName"
         placeholder="User Name"
         className="inputBox"
         value={this.state.userName}
         onChange={e =>this.handleUserName(e)}
        // value={firstName}
       //  onChange={e => this.handleInputChange(e)}
        />
        
        <label>Password :</label>
        <input
         name="password"
         type="password"
         placeholder="Password"
         className="inputBox"
         value={this.state.password}
         onChange={e =>this.handlePassword(e)}
         //value={password}
        // onChange={e => this.handleInputChange(e)}
        />
        
        <label>Select User :</label>
        <select
          labelId="demo-simple-select-placeholder-label-label"
          id="demo-simple-select-placeholder-label"
          value={this.state.selectedProduct}
          onChange={this.handleChange}
          displayEmpty
          className=""
        >
          <option value={1}>Please select</option>
          <option value={2}>QC</option>
          <option value={3}>Data Entry Operator</option>
        </select>
        
        <button className="btn" onClick={this.redirectToPage}>Login</button>
        {/* <h5>Infinity life</h5> */}
        {/* <HorizontalLabelPositionBelowStepper/> */}
        </div>
      </div>
    );
  }
}

export default Home;
