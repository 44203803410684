import React from "react";
import '../../App.css';
import './qualityCheck.css';
import img5 from '../../img/img5.jpg';
import commonLogo from '../../img/fdLogo.svg';
import userImg from '../../img/img2.jpg';
import NotificationsIcon from '@material-ui/icons/Notifications';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import HorizontalLabelPositionBelowStepper from "./qualityCheckStepperForm";

class QualityCheck extends React.Component {
  constructor() {
    super();
    this.state = {

    };
  }
  logout = () => {
    this.props.history.push("/login");
  }
  logoClick=()=>{
    this.props.history.push("/home");
  }
  render() {
    return (
      <div className="qcWrapper">
        <div className="header">
          <span className="fl logo">
            <img src={commonLogo} className="logo"  onClick={this.logoClick} />
          </span>
          <div className="fr navigation">
            <ul><li><a href="/#/qualityCheck">Home</a></li>
            {/* <li>Products
                <ul><li><a href="/#/infinityLife">Infinity Life</a></li></ul>
                <ul><li><a href="/#/superSecure">Super Secure</a></li></ul>
              </li>
              <li><a href="/#/home">Claims</a></li>
              <li><a href="/#/home">Your Policies</a></li>
              <li className="notif"><a href="#"><NotificationsIcon /></a></li> */}
              <li><a href="/#/home" className="subNav"><span><img src={userImg} className="userIcn" /> </span></a>
                <ul><li><a href="/#/login"><ExitToAppIcon className="icn"/>Logout</a></li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
        <div className="bannerBox fl">
          <div className="contentBox">
            <h4>Quality Check</h4>
            <p>Quality Check offers multiple investment options to enable you to accumulate funds towards your desired goals, while providing you with an all-important insurance cover that will protect those goals even in your absence.</p>
            <hr className="dotted" />
            <h3>Key Benefits</h3>
            <ul className="listing1">
              <li>Savings oriented unit linked insurance policy with the option to select 101% of the fund value as Death cover or any other amount subject to underwriting approval.</li>
              <li>An inbuilt passive war risk-benefit </li>
              <li>Upfront Bonus </li>
              <li>Loyalty Additio</li>
            </ul>
          </div>
        </div>
        <div className="formBox fr">
          <h5 className="pageTitle">Quality Check - Infinity Life</h5>

          <HorizontalLabelPositionBelowStepper />
        </div>
      </div>
    );
  }
}

export default QualityCheck;
