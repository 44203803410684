import React from "react";
import commonLogo from '../../img/fdLogo.svg';
import userImg from '../../img/img2.jpg';
import img1 from '../../img/img1.jpg';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import NotificationsIcon from '@material-ui/icons/Notifications';
//import HorizontalLabelPositionBelowStepper from './infinityLifeStepperForm'
import CardDisplay from 'f1-card-react'
import img5 from '../../img/img5.jpg'
import img4 from '../../img/img4.jpg'

import './home.css'

class Home extends React.Component {
  constructor() {
    super();
    this.state = {
      selectedProduct: 1,
    };
  }
  handleChange = (e) => {
    this.setState({ selectedProduct: e.target.value });
    if (e.target.value == 1) {
      this.props.history.push("/home");
    } else if (e.target.value == 2) {
      this.props.history.push("/infinityLife");
    } else {
      this.props.history.push("/superSecure");
    }
  };

  logout = () => {
    this.props.history.push("/login");
  }

  InfinityClick = () => {
    this.props.history.push("/infinityLife");
  }

  superClick = () => {
    this.props.history.push("/superSecure");
  }
  logoClick = () => {
    this.props.history.push("/home");
  }
  render() {
    return (

      <div className="wrapper homeContainer">
        {/* <div className="header">
<span className="fl logo">
<img src={commonLogo} className="logo" />
</span>
<div className="fr navigation">Logo</div>

        
</div>

        <label>Select Product :</label>
        <select
          labelId="demo-simple-select-placeholder-label-label"
          id="demo-simple-select-placeholder-label"
          value={this.state.selectedProduct}
          onChange={this.handleChange}
          displayEmpty
          className=""
        >
          <option value={1}>Please select</option>
          <option value={2}>Infinity Life</option>
          <option value={3}>Super Secure</option>
        </select> */}



        <div className="header">
          <span className="fl logo">
            <img src={commonLogo} className="logo" onClick={this.logoClick} />
          </span>
          <div className="fr navigation">
            <ul><li><a href="/#/home">Home</a></li>
              <li>Products
                <ul style={{ width: "140px" }}><li><a href="/#/infinityLife">Infinity Life</a></li>
                  <li><a href="/#/superSecure">Super Secure</a></li></ul>
              </li>
              <li><a href="/#/home">Claims</a></li>
              <li><a href="/#/home">Your Policies</a></li>
              <li className="notif"><a href="#"><NotificationsIcon /></a></li>
              <li><a href="/#/home" className="subNav"><span><img src={userImg} className="userIcn" /> </span></a>
                <ul><li><a href="/#/login"><ExitToAppIcon className="icn" />Logout</a></li>
                </ul>
              </li>
            </ul>
          </div>
        </div>

        <div className="bannerBox fl">
        <img src={img1} className="bannerBoxImg" />
        
        </div>


        <div className="formBox fr">
          <h5 className="pageTitle">Quality Check - Infinity Life</h5>
          <div className="cardBox">
            <CardDisplay
              width={"18rem"}
              border={""}
              // 'primary', 'secondary', 'success', 'danger', 'warning', 'info', 'light', 'dark'
              bg={''}
              // 'primary', 'secondary', 'success', 'danger', 'warning', 'info', 'light', 'dark'
              text={''}
              // 'light', 'dark', 'white'
              isImage={{
                isImg: false,
                variant: "bottom",
                src: img5,
                width: "100%",
                height: "150px"
              }}
              body={

                <div>
                  <div style={{ display: "inline-block", position: "relative", top: "-10px" }}>
                    <img src={img5} style={{ width: '100%' }} />
                  </div>
                  <div style={{ display: "inline-block" }}>
                    <p style={{ color: 'red', fontWeight: "bold" }}>Life Insurance</p>
                    <div className='infinityClass'>Infinity Life</div>
                    <p className='bottomText'>Our comprehensive coverage is designed to provide your family with firm support through every contingency.</p>
                  </div>
                  <div style={{ display: "inline-block", float: "left", verticaleAlign: "middle" }}>
                    <button variant="outline-secondary" className="buyButton" onClick={this.InfinityClick}>Make Entry</button>
                  </div>
                </div>
              }
              textAlign={"left"}
            />
          </div>

          <div className="cardBox">
            <CardDisplay
              width={"18rem"}
              border={""}
              // 'primary', 'secondary', 'success', 'danger', 'warning', 'info', 'light', 'dark'
              bg={''}
              // 'primary', 'secondary', 'success', 'danger', 'warning', 'info', 'light', 'dark'
              text={''}
              // 'light', 'dark', 'white'
              isImage={{
                isImg: false,
                variant: "bottom",
                src: img5,
                width: "100%",
                height: "150px"
              }}
              body={

                <div>
                  <div style={{ display: "inline-block", position: "relative", top: "-10px" }}>
                    <img src={img4} style={{ height: '142px', width: '247px' }} />
                  </div>
                  <div style={{ display: "inline-block" }}>
                    <p style={{ color: 'red', fontWeight: "bold" }}>Life Insurance</p>
                    <div className='infinityClass'>Super Secure</div>
                    <p className='bottomText'>Our comprehensive coverage is designed to provide your family with firm support through every contingency.</p>
                  </div>
                  <div style={{ display: "inline-block", float: "left", verticaleAlign: "middle" }}>
                    <button variant="outline-secondary" className="buyButton" onClick={this.superClick}>Make Entry</button>
                  </div>
                </div>
              }
              textAlign={"left"}
            />
          </div>
        </div>
      </div>








    );
  }
}

export default Home;
