export const personalDetails=[



  {
      "fieldSetClassName": "custom-fieldgroup",
      "fieldSetLabelType": "string", // string/component
      "fieldSetLabel": "",
      "columns":[
        {
          
            "rows":[
              [
                  { "colmd":"6",
                      "key": "policyType",
                      "label": "What Type of Policy Do You Require?",
                      "type": "select",
                      "disabled": true,                    
                      "id":"city",
                      "options": [
                     
                        //{ "key": "Select", "label": "Please Select Policy Type", "value": "" },
                        { "key": "singleLife", "label": "Single Life", "value": "singleLife" },
                        { "key": "jointLife_FirstDeaths", "label": "Joint Life - First Deaths", "value": "jointLife_FirstDeaths" },
                        { "key": "jointLife_BothDeath", "label": "Joint Life - Both Death", "value": "jointLife_BothDeath" }
                      ],
                     "validators":[{"name":"required", "value":true}]
                    },
                    {
                      "colmd":"6",
                      "key": "policyCurrency",
                      "label": "Policy Currency",
                      "type": "select",
                      "disabled": true,
                   
                      "id":"policyCurrency",
                      "options": [
                    
                        //{ "key": "Select", "label": "Please Select Policy Currency", "value": "" },
                        { "key": "USD", "label": "USD", "value": "USD" },
                        { "key": "AED", "label": "AED", "value": "AED" }
                      ],
                      "validators":[{"name":"required", "value":true}]
                    },
              
                {
                  "colmd":"6",
                  
                      "key": "policyOwner",
                      "label": "Who Is The Policy Owner?",
                      "type": "select",
                      "disabled": true,                     
                      "id":"city",
                      "options": [
                    
                        //{ "key": "Select", "label": "Please Select Policy Owner", "value": "" },
                        { "key": "firstLifetobeAssured", "label": "First Life to be Assured", "value": "firstLifetobeAssured" },
                        { "key": "secondLifetobeAssured", "label": "Second Life to be Assured", "value": "secondLifetobeAssured" },
                        { "key": "ifDifferentfromLifetobeAssured", "label": "If Different from Life to be Assured", "value": "ifDifferentfromLifetobeAssured" }
                      ],
                     "validators":[{"name":"required", "value":true}]
                    },
              ]
            ]
        }
      ]
  
    }
  ]
    
      export const firstLifeToBeAssured=[  
          {
              "fieldSetClassName": "custom-fieldgroup",
              "fieldSetLabelType": "string", // string/component
              "fieldSetLabel": "",
              "columns":[
                {
                  
                    "rows":[
                      [
                          {"colmd":"2",
                              "key": "title",
                              "label": "Title",
                              "type": "select",
                              "disabled": true,
                              "value": "mr",
                              "id":"title",
                              "options": [
                                // { "key": "Select", "label": "Please Select Title", "value": "" },
                                { "key": "mr", "label": "Mr", "value": "mr" },
                                { "key": "mrs", "label": "Mrs", "value": "mrs" },
                                { "key": "miss", "label": "Miss", "value": "miss" },
                                { "key": "other", "label": "Other", "value": "other" }
                              ],
                              "validators":[{"name":"required", "value":true}]
                            },
                  
                            { "colmd":"2","key": "firstName", "disabled":true, "id":"firstName", "icon":"user", "label": "First Name", "validators":[{"name":"required", "value":true}, {"name":"minLength", "value":2},{"name":"maxLength", "value":10, "validationMessage": "Maximum 10 alphabets allowed."}]},
                  
                            { "colmd":"4","key": "lastName", "disabled":true, "id":"lastName", "icon":"user", "label": "Last Name", "validators":[{"name":"required", "value":true}, {"name":"minLength", "value":2},{"name":"maxLength", "value":10, "validationMessage": "Maximum 10 alphabets allowed."}]},
                  
                            {"colmd":"4",
                              "key": "gender",
                              "label": "Gender",
                              "type": "select",
                              "disabled": true,
                              "value": "male",
                              "id":"gender",
                              "options": [
                                // { "key": "Select", "label": "Please Select Gender", "value": "" },
                                { "key": "male", "label": "Male", "value": "male" },
                                { "key": "female", "label": "Female", "value": "female" }
                              ],
                              "validators":[{"name":"required", "value":true}]
                            },
                  
                            { "colmd":"4","key": "countryOfBirth", "disabled":true, "id":"countryOfBirth", "icon":"user", "label": "Country of Birth Name", "validators":[{"name":"required", "value":true}, {"name":"minLength", "value":2},{"name":"maxLength", "value":10, "validationMessage": "Maximum 10 characters allowed."}]},
                  
                            { "colmd":"4","key": "nationality", "disabled":true, "id":"nationality", "icon":"user", "label": "Nationality", "validators":[{"name":"required", "value":true}, {"name":"minLength", "value":2},{"name":"maxLength", "value":10, "validationMessage": "Maximum 10 characters allowed."}]},
                  
                            { "colmd":"4","key": "countryOfResidence", "disabled":true, "id":"countryOfResidence", "icon":"user", "label": "Country of Residence", "validators":[{"name":"required", "value":true}, {"name":"minLength", "value":2},{"name":"maxLength", "value":10, "validationMessage": "Maximum 10 characters allowed."}]}
                              ,
                              {"colmd":"4",
                              "key": "Date",
                              "label": "Date of Birth",
                              "disabled": true,
                              "type": "date",
                              "max": "2020-10-20",
                              },
                  
                              {"colmd":"4",
                                "key": "maritalStatus",
                                "label": "Marital Status",
                                "type": "select",
                                "value": "maritalStatus",
                                "id":"Single",
                                "disabled": true,
                                "options": [
                                  // { "key": "Select", "label": "Please Select Marital Status", "value": "" },
                                  { "key": "single", "label": "Single", "value": "single" },
                                  { "key": "married", "label": "Married", "value": "married" },
                                  { "key": "other", "label": "Other", "value": "other" }
                                ]
                              },
                              {"colmd":"6",
                                "key": "residentialAddress",
                                "label": "Residential Address",
                                "type": "textarea",
                                "rows":"5",
                                "disabled": true,
                                "validators":[{"name":"required", "value":true}]
                              },
                  
                              
                              {
                                "key": "correspondanseAddress",
                                "disabled": true,
                                "label": "Correspondance Address (If It's Differemt)",
                                "type": "textarea",
                                "rows":"5",
                                "validators":[{"name":"required", "value":true}]
                              },
                              {"colmd":"6",
                                "key": "homeCountryAddress",
                                "label": "Home Country Address",
                                "type": "textarea",
                                "disabled": true,
                                "rows":"5",
                                "validators":[{"name":"required", "value":true}]
                              },
                              {"colmd":"6",
                                "key": "adressStatus",
                                "disabled": true,
                                "label": "Is this a Permanent or Temporary Address?",
                                "type": "select",
                                "value": "maritalStatus",
                                "id":"Single",
                                "options": [
                                  // { "key": "Select", "label": "Please Select Address Type", "value": "" },
                                  { "key": "permanent", "label": "Permanent", "value": "permanent" },
                                  { "key": "temproary", "label": "Temproary", "value": "temproary" }
                                ]
                              },
                              {"colmd":"6",
                                "key": "mobileNumber",
                                "label": "Mobile Number",
                                "type": "tel",
                                "disabled": true,
                                "placeholder":"123-45-678",
                                "required": true
                              },
                              { "colmd":"6","key": "email",  "disabled": true,"icon":"envelope", "label": "Email Address", "validators":[{"name":"required", "value":true},{"name":"minLength", "value":5},{"name":"isEmail", "value":true}]},
                              {"colmd":"6",
                                "key": "telephoneNumber",
                                "label": "Telephone Number",
                                "type": "tel",
                                "disabled": true,
                                "placeholder":"123-45-678",
                                "required": true
                              },
                              {"colmd":"6",
                                "key": "faxNumber",
                                "label": "Fax Number",
                                "disabled": true,
                                "type": "tel",
                                "placeholder":"123-45-678",
                                "required": true
                              },
                              {"colmd":"6",
                                "key": "occupationAndPosition",
                                "disabled": true,
                                "label": "What is your current occupation and position you hold?",
                                "type": "textarea",
                                "rows":"5",
                                "validators":[{"name":"required", "value":true}]
                              },
                  
                              
                              {"colmd":"6",
                                "key": "companyAddress",
                                "label": "Company Address",
                                "disabled": true,
                                "type": "textarea",
                                "rows":"5",
                                "validators":[{"name":"required", "value":true}],"layout":"2column"
                              },
                              { "colmd":"6","key": "companyName", "disabled":true, "id":"companyName", "icon":"user", "label": "Company Name", "validators":[{"name":"required", "value":true}, {"name":"minLength", "value":2},{"name":"maxLength", "value":10, "validationMessage": "Maximum 10 alphabets allowed."}],"layout":"2column"} 
                      ]
                    ]
                }
              ]
          
            } 
      ]

      export const beneficaryDetails=[  
  
          {
              "fieldSetClassName": "custom-fieldgroup",
              "fieldSetLabelType": "string", // string/component
              "fieldSetLabel": "",
              "columns":[
                {
                  "colmd":"12",
                    "rows":[
                      [ { "colmd":"6","key": "nameOfBeneficary", "disabled":true, "id":"nameOfBeneficary", "icon":"user", "label": "Name of Beneficary(s)", "validators":[{ "value":true}, {"name":"minLength", "value":2},{"name":"maxLength", "value":10, "validationMessage": "Maximum 10 alphabets allowed."}]},
                      {"colmd":"6",
                      "key": "Date",
                      "label": "Date of Birth",
                      "type": "date",
                      "disabled":true
                      },
                      { "colmd":"6","key": "relationshipToLifeToBeAssured", "disabled":true, "id":"relationshipToLifeToBeAssured", "icon":"user", "label": "Relationship to Life to be Assured", 
                      "validators":[{ "value":true}, {"name":"minLength", "value":2},{"name":"maxLength", "value":10, "validationMessage": "Maximum 10 alphabets allowed."}]},
                      {
                        "colmd":"6",
                        "key": "percentage",
                        "label": "Percentage",
                        "type": "number",
                        "disabled": true,
                        "props": { "min": 0, "max": 100.00 }
                      },
                      {
                        "colmd":"6",
                        "key": "minorBeneficiary",
                        "type": "label",
                        "disabled": true,
                        "value": "If beneficary(s) is a minor, Please give apointee details.",
                        "className": "custom-label"
                      },
                      { "colmd":"6","key": "nameOfAppointee", "disabled":true, "id":"Name of Appointee", "icon":"user", "label": "Name of Appointee", 
                      "validators":[{ "value":true}, {"name":"minLength", "value":2},{"name":"maxLength", "value":10, "validationMessage": "Maximum 10 alphabets allowed."}]},
                              {"colmd":"6",
                              "key": "DobApointee",
                              "label": "Date of Birth",
                              "type": "date",
                              "disabled":true
                              },
                              { "colmd":"6","key": "relationshipToBeneficary", "disabled":true, "id":"relationshipToBeneficary", "icon":"user", "label": "Relationship to  Beneficary(s)", 
                              "validators":[{"value":true}, {"name":"minLength", "value":2},{"name":"maxLength", "value":10, "validationMessage": "Maximum 10 alphabets allowed."}]}
  
                         
                           
                      ]
                    ]
                }
              ]
          
            }
          ]
  
          export const investmentDetails=[  
            {
                "fieldSetClassName": "custom-fieldgroup",
                "fieldSetLabelType": "string", // string/component
                "fieldSetLabel": "",
                "columns":[
                  {
                    "colmd":"12",
                      "rows":[
                        [ 
                                                     
                                { "colmd":"6","key": "investmentLabel1", "disabled":true, "id":"investment1", "icon":"user", 
                                "validators":[{"name":"required", "value":true}, {"name":"minLength", "value":2},{"name":"maxLength", "value":10, "validationMessage": "Maximum 10 alphabets allowed."}]},
  
                                {
                                  "key": "investment1",
                                  "type": "number",
                                  "props": { "min": 0, "max": 100.00, "disabled":true }
                                },
                                { "colmd":"6","key": "investmentLabel2", "disabled":true, "id":"investment2", "icon":"user", 
                                "validators":[{"name":"required", "value":true}, {"name":"minLength", "value":2},{"name":"maxLength", "value":10, "validationMessage": "Maximum 10 alphabets allowed."}]},
  
                                {
                                  "key": "investment2",
                                  "type": "number",
                                  "props": { "min": 0, "max": 100.00, "disabled":true }
                                },
                                { "colmd":"6","key": "investmentLabel3", "disabled":true, "id":"investment3", "icon":"user", 
                                "validators":[{"name":"required", "value":true}, {"name":"minLength", "value":2},{"name":"maxLength", "value":10, "validationMessage": "Maximum 10 alphabets allowed."}]},
  
                                {
                                  "key": "investment3",
                                  "type": "number",
                                  "props": { "min": 0, "max": 100.00, "disabled":true }
                                },
                                { "colmd":"6","key": "investmentLabel4", "id":"investmentLabel4", "icon":"user", 
                                "disabled":true,
                                "validators":[{"name":"required", "value":true}, {"name":"minLength", "value":2},{"name":"maxLength", "value":10, "validationMessage": "Maximum 10 alphabets allowed."}]},
  
                                {
                                  "key": "investment4",
                                  "type": "number",
                                  "props": { "min": 0, "max": 100.00 ,"disabled":true}
                                }
                        ]
                      ]
                  }
                ]
            
              }
            ]