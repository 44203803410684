import React from "react";
import firstLifeToBeAssuredJson from './firstLifeToBeAssured.json';
import personalDetailsJson from './personalDetailsJson.json';


export const personalDetails=[
  {
      "fieldSetClassName": "custom-fieldgroup",
      "fieldSetLabelType": "string", // string/component
      "fieldSetLabel": "",
      "columns":personalDetailsJson
        
      
  
    }
  ]
    
      export const firstLifeToBeAssured=[  
          {
              "fieldSetClassName": "custom-fieldgroup",
              "fieldSetLabelType": "string", // string/component
              "fieldSetLabel": "",
              "columns":firstLifeToBeAssuredJson
          
            } 
      ]

      export const beneficaryDetails=[  
  
          {
              "fieldSetClassName": "custom-fieldgroup",
              "fieldSetLabelType": "string", // string/component
              "fieldSetLabel": "",
              "columns":[
                {
                  "colmd":"12",
                    "rows":[
                      [ { "colmd":"6","key": "nameOfBeneficary", "disabled":false, "id":"nameOfBeneficary", "icon":"user", "label": "Name of Beneficary(s)", "validators":[{"name":"required", "value":true}, {"name":"minLength", "value":2},{"name":"maxLength", "value":10, "validationMessage": "Maximum 10 alphabets allowed."}]},
                      {"colmd":"6",
                      "key": "Date",
                      "label": "Date of Birth",
                      "type": "date"
                      },
                      { "colmd":"6","key": "relationshipToLifeToBeAssured", "disabled":false, "id":"relationshipToLifeToBeAssured", "icon":"user", "label": "Relationship to Life to be Assured", 
                      "validators":[{"name":"required", "value":true}, {"name":"minLength", "value":2},{"name":"maxLength", "value":10, "validationMessage": "Maximum 10 alphabets allowed."}]},
                      {
                        "colmd":"6",
                        "key": "percentage",
                        "label": "Percentage",
                        "type": "number",
                        "props": { "min": 0, "max": 100.00 }
                      },
                      {
                        "colmd":"6",
                        "key": "minorBeneficiary",
                        "type": "label",
                        "value": "If beneficary(s) is a minor, Please give apointee details.",
                        "className": "custom-label"
                      },
                      { "colmd":"6","key": "nameOfAppointee", "disabled":false, "id":"Name of Appointee", "icon":"user", "label": "Name of Appointee", 
                      "validators":[{"name":"required", "value":true}, {"name":"minLength", "value":2},{"name":"maxLength", "value":10, "validationMessage": "Maximum 10 alphabets allowed."}]},
                              {"colmd":"6",
                              "key": "DobApointee",
                              "label": "Date of Birth",
                              "type": "date"
                              },
                              { "colmd":"6","key": "relationshipToBeneficary", "disabled":false, "id":"relationshipToBeneficary", "icon":"user", "label": "Relationship to  Beneficary(s)", 
                              "validators":[{"name":"required", "value":true}, {"name":"minLength", "value":2},{"name":"maxLength", "value":10, "validationMessage": "Maximum 10 alphabets allowed."}]}
  
                         
                           
                      ]
                    ]
                }
              ]
          
            }
          ]
  
          export const investmentDetails=[  
            {
                "fieldSetClassName": "custom-fieldgroup",
                "fieldSetLabelType": "string", // string/component
                "fieldSetLabel": "",
                "columns":[
                  {
                    "colmd":"12",
                      "rows":[
                        [ 
                                                     
                                { "colmd":"6","key": "investmentLabel1", "disabled":false, "id":"investment1", "icon":"user", 
                                "validators":[{"name":"required", "value":true}, {"name":"minLength", "value":2},{"name":"maxLength", "value":10, "validationMessage": "Maximum 10 alphabets allowed."}]},
  
                                {
                                  "key": "investment1",
                                  "type": "number",
                                  "props": { "min": 0, "max": 100.00 }
                                },
                                
                                
                        ]
                      ]
                  }
                ]
            
              }
            ]