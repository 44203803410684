import React, { useEffect, useState,useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import DynamicForm from "f1-dynamic-form-react";
import { personalDetails, firstLifeToBeAssured, beneficaryDetails,investmentDetails } from './qualityCheckJson'
import ModalPopup from 'f1-modal-react'
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

function getSteps() {
  
  return ['PERSONAL DETAILS', 'FIRST LIFE TO BE ASSURED', 'BENEFICARY(S) DETAILS', 'INVESTMENT DETAILS'];
}



export default function HorizontalLabelPositionBelowStepper() {

  const [formJson, setFormJson] = React.useState({
    personalDetails: personalDetails,
    firstLifeToBeAssured: firstLifeToBeAssured,
    beneficaryDetails: beneficaryDetails,
    investmentDetails:investmentDetails
  })
  const [personalDetailsDefaultValue, setPersonalDetailsDefaultValue] = React.useState({
    policyType: { "key": "singleLife", "label": "Single Life", "value": "singleLife" },
    policyCurrency: { "key": "USD", "label": "USD", "value": "USD" },
    policyOwner: { "key": "firstLifetobeAssured", "label": "First Life to be Assured", "value": "firstLifetobeAssured" }
  })

  const [firstLifeToBeAssuredDefaultValue, setFirstLifeToBeAssuredDefaultValue] = React.useState({
    title: { "key": "mr", "label": "Mr", "value": "mr" },
    firstName: "John",
    lastName: "Doe",
    gender:{ "key": "male", "label": "Male", "value": "male" },
    countryOfBirth:"US",
    nationality:"US",
    countryOfResidence:"US",
    Date:'2020-10-10',
    maritalStatus: { "key": "single", "label": "Single", "value": "single" },
    residentialAddress:"NY",
    adressStatus:  { "key": "permanent", "label": "Permanent", "value": "permanent" },
    correspondanseAddress:"NY",
    homeCountryAddress:"NY",
    mobileNumber:1234567890,
    email:"john@gmail.com",
    telephoneNumber:1234567890,
    faxNumber:1234567890,
    occupationAndPosition:"Service/Software developer",
    companyName:"Fulcrum ",
    companyAddress:"NY"

 
  })

  const [investmentDetailsDefaultValue, setInvestmentDetailsDefaultValue] = React.useState({
    investmentLabel1:"BGF World Gold A2",
    investment1: 25,
    investmentLabel2:"FRANKLIN US OPPORTUNITIES",
    investment2: 25,
    investmentLabel3:"PICTET CH PM",
    investment3: 50,
    investmentLabel4:"TOTAL",
    investment4:100
  })

  
const EnableData1=()=>{
  setDisableSaveBtn1(false);
  let personalDetailsValidationData = formJson.personalDetails.map(item=>{
    console.log("mapped data",item)
    item.columns.map(item2=>{
      console.log("Mapped data2",item2)
      item2.rows.map(el=>{
        console.log("Mapped data3",el)
        el.map(child=>{
          console.log("Mapped data4",child)
          child.disabled = false
          console.log("Mapped data5",child)
          return child

        })
        return el
      })
      return item2
    })
  return item
  })

  setFormJson({...formJson,
    personalDetails: personalDetailsValidationData
  })

  setPersonalDetailsDefaultValue(personalDetailsDefaultValue)

}
const EnableData2=()=>{
  setDisableSaveBtn2(false);
  let firstLifeToBeAssuredValidationData = formJson.firstLifeToBeAssured.map(item=>{
    console.log("mapped data",item)
    item.columns.map(item2=>{
      console.log("Mapped data2",item2)
      item2.rows.map(el=>{
        console.log("Mapped data3",el)
        el.map(child=>{
          console.log("Mapped data4",child)
          child.disabled = false
          console.log("Mapped data5",child)
          return child

        })
        return el
      })
      return item2
    })
  return item
  })
  setFormJson({...formJson,
    firstLifeToBeAssured: firstLifeToBeAssuredValidationData,
  })

  setFirstLifeToBeAssuredDefaultValue(firstLifeToBeAssuredDefaultValue)
}

const EnableData3 = ()=>{
   
  setDisableSaveBtn3(false);
  setDisabledNominee(false);
  let beneficaryDetailsValidationData = formJson.beneficaryDetails.map(item=>{
    console.log("mapped data",item)
    item.columns.map(item2=>{
      console.log("Mapped data2",item2)
      item2.rows.map(el=>{
        console.log("Mapped data3",el)
        el.map(child=>{
          console.log("Mapped data4",child)
          child.disabled = false
          console.log("Mapped data5",child)
          return child

        })
        return el
      })
      return item2
    })
  return item
  })

  setFormJson({
  ...formJson,
    beneficaryDetails: beneficaryDetailsValidationData
    
  })
}
const EnableData = ()=>{
  setDisableSaveBtn4(false);
  setDisabledInvestmentDetails(false);
    let investmentDetailsValidationData = formJson.investmentDetails.map(item=>{
      console.log("mapped data",item)
      item.columns.map(item2=>{
        console.log("Mapped data2",item2)
        item2.rows.map(el=>{
          console.log("Mapped data3",el)
          el.map(child=>{
            console.log("Mapped data4",child)
            child.disabled = false
            console.log("Mapped data5",child)
            return child

          })
          return el
        })
        return item2
      })
    return item
    })
   
    setFormJson({
     ...formJson,
      investmentDetails:investmentDetailsValidationData
    })
    setInvestmentDetailsDefaultValue(investmentDetailsDefaultValue)

  }
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const steps = getSteps();
  const [formData, setFormData] = React.useState({})
  let allData = {}
  const [approvedStep,setApprovedStep]=useState([])
  const [inputList, setInputList] = useState([{ firstName: "Franklin US opportunities A(acc) USD", lastName: 100}]);
  const [inputListNominee, setInputListNominee] = useState([{ nameBenificiary: "Jon", DOB: "1998-10-20",relationToLifeAssured:"Father",percentage:100 }]);
  let [age,setAge]=React.useState(20);
  const [total,setTotal]= React.useState(100);
  const [Percentage,setPercentage]= React.useState(100);
  const dynamicFormRef = useRef(null);
  const [disabledNominee,setDisabledNominee]= React.useState(true);
  const [disabledInvestmentDetails,setDisabledInvestmentDetails]= React.useState(true);
  const [disableSaveBtn1,setDisableSaveBtn1]= React.useState(true);
  const [disableSaveBtn2,setDisableSaveBtn2]= React.useState(true);
  const [disableSaveBtn3,setDisableSaveBtn3]= React.useState(true);
  const [disableSaveBtn4,setDisableSaveBtn4]= React.useState(true);
  
  const onSubmit = (model,name) => {
    allData = { ...formData, ...model }
    if (model.isValidForm || Object.keys(model.errors).length === 0) {
    setFormData(allData)
        if(name==="Personal Details"){
      setApprovedStep([...approvedStep,{"name":"PERSONAL DETAILS"}]);
      alert(name + " validated successfully!")
     // setDisableSaveBtn1(false);
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
    if(name==="FIRST LIFE TO BE ASSURED"){
      alert(name + " validated successfully!")
   //   setDisableSaveBtn2(false);
      setApprovedStep([...approvedStep,{"name":"FIRST LIFE TO BE ASSURED"}]);
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
    if(name==="INVESTMENT DETAILS" && activeStep === 3){
      if(total>100){
        alert('Total percentage should not be greater than 100%');
      }else if(total<100){
        alert('Total percentage should not be less than 100%');
      }else{
     //   setDisableSaveBtn4(false);
        alert(name + " validated successfully!")
        setApprovedStep([...approvedStep,{"name":"INVESTMENT DETAILS"}]);
        // setActiveStep((prevActiveStep) => prevActiveStep + 1);
      }
     
    }
    if(name==="BENEFICARY(S) DETAILS" && activeStep === 2){
      if(Percentage>100){
        alert('Total percentage should not be greater than 100%');
      }else if(Percentage<100){
        alert('Total percentage should not be less than 100%');
      }else{
     //   setDisableSaveBtn3(false);
        alert(name + " validated successfully!")
        setApprovedStep([...approvedStep,{"name":"BENEFICARY(S) DETAILS"}]);
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      }
     
    }
    
    }
 
    console.log(approvedStep,"modellll")
  }
  const [modalShow,setModalShow]=useState(false)
  const handleNext = () => {

let a=steps.length - 1 

    if(activeStep === a){
      if(total>100){
        alert('Total percentage should not be greater than 100%');
      }else if(total<100){
        alert('Total percentage should not be less than 100%');
      }else{
        let result = approvedStep.filter((v,i,a)=>a.findIndex(t=>(JSON.stringify(t) === JSON.stringify(v)))===i)
 
      setApprovedStep(result)
      setModalShow(!modalShow)
      }

     
    }else if(activeStep === 2){
      if(Percentage>100){
        alert('Total percentage should not be greater than 100%');
      }else if(Percentage<100){
        alert('Total percentage should not be less than 100%');
      }else{
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      }
    }
    else{
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  }
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
    setModalShow(false)
    setApprovedStep([])
  };

  const saveData=(name)=>{
   
    
    if(name==="Personal Details"){
      alert(name + " save successfully!")
      setDisableSaveBtn1(true);
    }
    if(name==="FIRST LIFE TO BE ASSURED"){
      alert(name + " save successfully!")
      setDisableSaveBtn2(true);
    }
    if(name==="INVESTMENT DETAILS"){
     alert(name + " save successfully!");
     setDisableSaveBtn3(true);
    }
    if(name==="BENEFICARY(S) DETAILS" && activeStep === 2){
      alert(name + " save successfully!")
      setDisableSaveBtn4(true);
    }
  }

   // handle input change
const handleInputChange = (e, index) => {

  const { name, value } = e.target;
  const list = [...inputList];
  list[index][name] = value;
  let tempTotal=0
  for(var i=0;i<list.length;i++){
    tempTotal=tempTotal+parseInt(list[i].lastName);
  }
  debugger;
  if(tempTotal){
    setTotal(tempTotal);
  }
  
  setInputList(list);
};
 
// handle click event of the Remove button
const handleRemoveClick = index => {
  const list = [...inputList];
  list.splice(index, 1);
  let tempTotal=0
  for(var i=0;i<list.length;i++){
    tempTotal=tempTotal+parseInt(list[i].lastName);
  }
  debugger;
  if(tempTotal){
    setTotal(tempTotal);
  }
  setInputList(list);
};
 
// handle click event of the Add button
const handleAddClick = () => {
  console.log('inputList',inputList);
  setInputList([...inputList, { firstName: "", lastName: "" }]);
};

// const onChangeHandler=(e)=>{
//   if(e.target.id=='title'){
//     if(e.target.value=='mr'){
//       setFirstLifeToBeAssuredDefaultValue([...firstLifeToBeAssuredDefaultValue,{ "key": "male", "label": "Male", "value": "male" }])
       
//     }else{
//       setFirstLifeToBeAssuredDefaultValue([...firstLifeToBeAssuredDefaultValue,{ "key": "female", "label": "female", "value": "female" }}])
//     }
//   }
//   console.log('model',e.target.value);

// }

const handleInputChangeNominee = (e, index) => {
  debugger;
  const { name, value } = e.target;
  const list = [...inputListNominee];
  list[index][name] = value;
  let tempTotal=0;
  console.log(list);
  for(var i=0;i<list.length;i++){
    tempTotal=tempTotal+parseInt(list[i].percentage);
  }
  for(var i=0;i<list.length;i++){
    let tempAge=getAge(list[i].DOB);
    console.log('tempAge',tempAge);
    if(tempAge<18){
      setAge(tempAge);
     }
    else if(list[i].DOB){
      setAge(20);
    }
  }
 
  if(tempTotal){
    setPercentage(tempTotal);
  }
  
  setInputListNominee(list);
};



function getAge(dateString) {
  var today = new Date();
  var birthDate = new Date(dateString);
  var age = today.getFullYear() - birthDate.getFullYear();
  var m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
  }
  return age;
}

// handle click event of the Remove button
const handleRemoveClickNominee = index => {
  const list = [...inputListNominee];
  list.splice(index, 1);
  let tempTotal=0
  for(var i=0;i<list.length;i++){
    tempTotal=tempTotal+parseInt(list[i].percentage);
  }
  for(var i=0;i<list.length;i++){
    let tempAge=getAge(list[i].DOB);
    console.log('tempAge',tempAge);
    if(tempAge<18){
      setAge(tempAge);
     }
    else{
      setAge(20);
    }
  }

  debugger;
  if(tempTotal){
    setPercentage(tempTotal);
  }
  setInputListNominee(list);
};
 
// handle click event of the Add button
const handleAddClickNominee = () => {
  console.log('inputListNominee',inputListNominee);
 
    setInputListNominee([...inputListNominee, { nameBenificiary: "", DOB: "",relationToLifeAssured:"",percentage:"" }]);

  
};
const formOnSpecialRequestChangeHandler = (event, key) => {
  let dynamicFormRefLocal = dynamicFormRef.current.state;
  if(key=='title'){
  if (event.target.value == 'mr') {
    dynamicFormRefLocal.advanceLayoutFields[3].options = [{ key: "male", label: "Male", value: "male" }];
  } else if (event.target.value == 'mrs' || event.target.value == "miss") {
    dynamicFormRefLocal.advanceLayoutFields[3].options = [{ key: "female", label: "Female", value: "female" }];
  } else {
    dynamicFormRefLocal.advanceLayoutFields[3].options = [{
      "key": "Select",
      "label": "Please Select Gender ",
      "value": "Select"
    },
    {
      "key": "male",
      "label": "Male",
      "value": "male"
    },
    {
      "key": "female",
      "label": "Female",
      "value": "female"
    }];
  }
  // if (event.target.value == 'mrs') {
  //   dynamicFormRefLocal.advanceLayoutFields[8].options = [{ key: "married", label: "Married", value: "married" }];
  // } else {
  //   dynamicFormRefLocal.advanceLayoutFields[8].options = [
  //     {
  //       "key": "Select",
  //       "label": "Please Select Marital Status",
  //       "value": ""
  //     },
  //     {
  //       "key": "single",
  //       "label": "Single",
  //       "value": "single"
  //     },
  //     {
  //       "key": "married",
  //       "label": "Married",
  //       "value": "married"
  //     },
  //     {
  //       "key": "other",
  //       "label": "Other",
  //       "value": "other"
  //     }
  //   ]
  // }
}
}

const validateData=()=>{
  setActiveStep((prevActiveStep) => prevActiveStep + 1);
}
  const getStepContent = (stepIndex) => {

    switch (stepIndex) {
      case 0:
        return (
          <div>
             <button className="btnEdit" onClick={EnableData1}><EditIcon className="icn"/>Edit</button>

            <DynamicForm
              // key={this.state.current.id}
              className="form step1"
              title=" "
              buttonSubmit="Validate"
              defaultValues={personalDetailsDefaultValue}
              model={formJson.personalDetails}
              onSubmit={(model) => {
                onSubmit(model,"Personal Details");
              }}
            
              design="advanceBootstrap" // material
           

            />
            <button className="btnSave btn" disabled={disableSaveBtn1} onClick={()=>saveData('Personal Details')}>Save</button>
          </div>

        );
      case 1:
        return <div>  <button className="btnEdit" onClick={EnableData2}><EditIcon className="icn"/>Edit</button>
        
        <DynamicForm
          // key={this.state.current.id}
          className="form step2"
          title=" "
          buttonSubmit="Validate"
          // onChange={e => 
          //   onChangeHandler(e)
          // }
          defaultValues={firstLifeToBeAssuredDefaultValue}
          model={formJson.firstLifeToBeAssured}
          onSubmit={(model) => {
            onSubmit(model,"FIRST LIFE TO BE ASSURED");
          }}
          onRef={el => {
            dynamicFormRef.current = el;
          }}
          onChange={formOnSpecialRequestChangeHandler}
          design="advanceBootstrap" // material

        />
        <button className="btnSave btn" disabled={disableSaveBtn2} onClick={()=>saveData('FIRST LIFE TO BE ASSURED')}>Save</button>
        </div>
        ;
      case 2:
        return <div>
             <button className="btnEdit" onClick={EnableData3}><EditIcon className="icn"/>Edit</button>
          
         <div className="App step3">
            
            {inputListNominee.map((x, i) => {
              return (
                <div className="box">
                  <input
                  disabled={disabledNominee}
                    name="nameBenificiary" placeholder="Name of Beneficary(s)"
                    value={x.nameBenificiary}
                    onChange={e => handleInputChangeNominee(e, i)}
                  />
                  <input type="date"
                   disabled={disabledNominee}
                    name="DOB" placeholder="Date of Birth"
                    value={x.DOB}
                    onChange={e => handleInputChangeNominee(e, i)}
                  />
                   <input
                    disabled={disabledNominee}
                    name="relationToLifeAssured" placeholder="Relationship to Life to be Assured"
                    value={x.relationToLifeAssured}
                    onChange={e => handleInputChangeNominee(e, i)}
                  />
                  <input type="number"
                   disabled={disabledNominee}
                    className="ml10"
                    name="percentage"
         placeholder="Percentage"
                    value={x.percentage}
                    onChange={e => handleInputChangeNominee(e, i)}
                  />
                  <div className="btn-box">
                    {inputListNominee.length !== 1 && <button
                      className="mr10 btnRemove"
                      onClick={() => handleRemoveClickNominee(i)}>Remove</button>}
                    {inputListNominee.length - 1 === i && <button className="btnAdd" onClick={handleAddClickNominee} disabled={Percentage>=100 || inputListNominee.length>=4}><AddIcon className="icn"/>Add Beneficary</button>}
                  </div>
                  
                </div>
                
              );
              
            })}
            {<div style={{ marginTop: 20 }}><p>Total Percentage:</p>{Percentage}</div>}
            {age<18?
            <div>
            <h3>If beneficary(s) is a minor, Please give apointee details.</h3>
            <input type="text"
                    name="relationToLifeAssured" placeholder="Name of Appointee"
                   
                   
            />
            <input type="date" max="2002-10-14"
                name="relationToLifeAssured" placeholder="Date of Birth"
                
            />
            {/* {appointeAge<18?<p>Age should be greater than 18</p>:""} */}
            <input  type="text"
              name="relationToLifeAssured" placeholder="Relationship to Beneficary(s)"
             
                  />
            </div>
            :""}
           
            <div>
            <DynamicForm
          // key={this.state.current.id}
          className="form"
          title=" "
          buttonSubmit="Validate"
         
          // defaultValues={this.state.current}
          model={formJson.beneficaryDetails}
          onSubmit={(model) => {
            onSubmit(model,"BENEFICARY(S) DETAILS");
          }}

         // design="advanceBootstrap" // material

        />
            </div>
            <button className="btnSave btn" disabled={disableSaveBtn3} onClick={()=>saveData('BENEFICARY(S) DETAILS')}>Save</button>
          </div>
        </div>;
      case 3:
        return <div>   <button className="btnEdit" onClick={EnableData}><EditIcon className="icn"/>Edit</button> 
        
       <div className="App step4">
            
            {inputList.map((x, i) => {
              return (
                <div className="box">
                  <input
                  disabled={disabledInvestmentDetails}
                    name="firstName"
         placeholder="Enter Fund Name"
                    value={x.firstName}
                    onChange={e => handleInputChange(e, i)}
                  />
                  <input type="number"
                   disabled={disabledInvestmentDetails}
                    className="ml10"
                    name="lastName"
         placeholder="Enter Fund Value"
                    value={x.lastName}
                    onChange={e => handleInputChange(e, i)}
                  />
                  <div className="btn-box">
                    {inputList.length !== 1 && <button
                      className="mr10 btnRemove"
                      onClick={() => handleRemoveClick(i)}>Remove</button>}
                    {inputList.length - 1 === i && <button onClick={handleAddClick} disabled={total>=100 || inputList.length>=10}><AddIcon className="icn"/>Add</button>}
                  </div>
                </div>
              );
            })}
            <div style={{ marginTop: 20 }}><p>Total:</p>{total}</div>
            <div>
            <DynamicForm
        // key={this.state.current.id}
        className="form"
        title=" "
        buttonSubmit="Validate"
        defaultValues={investmentDetailsDefaultValue}
        model={formJson.investmentDetails}
        onSubmit={(model) => {
          onSubmit(model,"INVESTMENT DETAILS");
        }}

       // design="advanceBootstrap" // material

      />
            </div>
            <button className="btnSave btn" disabled={disableSaveBtn4} onClick={()=>saveData('INVESTMENT DETAILS')}>Save</button>
          </div>
      </div>;
      default:
        return 'Investment Details';        //Premium Payment Insurance Coverage Details Health Questionnaire
    }
  }

  return (
    <div className={classes.root}>
     
      <Stepper activeStep={activeStep} alternativeLabel>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>

      <div className="pageContent">
      <h4>Please provide us your details to process further</h4>
      <p>Our comprehensive coverage is designed to provide your family with firm support through every contingency.</p>
      </div>
      <div>
        {activeStep === steps.length ? (
          <div>
            <Typography className={classes.instructions}>Thank you! steps are approved</Typography>
<Button onClick={handleReset}>Reset</Button>
          </div>
        ) : (
            <div>
              <Typography className={classes.instructions}>{getStepContent(activeStep)}</Typography>
              <div className="buttonSet2">
                <Button
                  disabled={activeStep === 0}
                  onClick={handleBack}
                  className={classes.backButton}
                >
                  Back
                </Button>
                <Button variant="contained" color="primary" onClick={handleNext}>
                  {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                </Button>

                <ModalPopup
            show = {modalShow}
            onHide={() => setModalShow(false)}
            size = {"lg"}                                       // "lg","sm" or "xl"
            centered = {true}                                   // vertically center the Dialog in the window
            modelHeader = {"Validate"}                     // to show model header
            modelContent = {<div>
{approvedStep.length>0?
approvedStep && approvedStep.map(el=>{
  return <p>{el.name}: <CheckCircleIcon color="primary"/></p>
}): <p>Please save steps data</p>}
<Button className="btnValidate" onClick={validateData} disabled={approvedStep.length>0?false:true} color="primary" variant="contained">Validate</Button>
            </div>}             // to show model content
            modelFooter = {""}             //// to show model footer
            backdrop = {'static'}                               // true, false or 'static'
            scrollable = {true}                                 //boolean
            // onEnter = {this.onEnter}                            //Callback fired before the Modal transitions in
            // onExit = {this.onExit}                              //Callback fired right before the Modal transitions out
            closeButtonShow = {true}
            />      
              </div>
            </div>
          )}
      </div>
    </div>
  );
}