import React from "react";
import "./App.css";
import { Route, Switch, HashRouter } from "react-router-dom";
import InfinityLife from "./Components/infinityLife/infinityLife";
import SuperSecure from "./Components/superSecure/superSecure";
import Home from "./Components/home/home";
import Login from "./Components/login/login"
import QualityCheck from './Components/qualityCheck/qualityCheck'

class App extends React.Component {
  constructor() {
    super();
  }
  render() {
    return (
      <div className="App">
        <HashRouter>
          <Switch>
             <Route path="/login" component={Login} /> 
            <Route path="/home" component={Home} />
            <Route path="/infinityLife" component={InfinityLife} />
            <Route path="/superSecure" component={SuperSecure} />
            <Route path="/qualityCheck" component={QualityCheck} />
          </Switch>
        </HashRouter>
      </div>
    );
  }
}

export default App;
